@import 'global/styles/variables';
@import 'global/styles/mixins';

.AboutUsChooseStoremItem {
    display: flex;
    gap: 24px;
    align-items: center;

    @include screen-lg {
        flex-direction: column;
    }

    &__img {
        flex: 1;
        height: 306px;
        border-radius: 16px;

        @include screen-lg {
            display: none;
        }
    }

    &__textContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        
        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include screen-lg {
                width: unset;
            }

            &__title {
                font-size: 32px;
                font-weight: 600;
                line-height: 44px;
                letter-spacing: 0.2px;
            }

            &__descriptionsContainer {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }

            &__description {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $color-text-grey;
            }
        }
    }

}