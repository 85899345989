@import 'global/styles/variables';
@import 'global/styles/mixins';

.IncludedInThisCard {
    margin-top: 96px;

    h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
    }

    div {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-row-gap: 50px;
        row-gap: 50px;
        grid-column-gap: 24px;
        -webkit-column-gap: 24px;
        column-gap: 24px;
        margin-top: 48px;

        @include screen-sm {
            grid-template-columns: repeat(2,1fr);
            grid-row-gap: 20px;
            row-gap: 20px;
        }

        @include screen-xs {
            grid-column-gap: 14px;
            -webkit-column-gap: 14px;
            column-gap: 14px;
        }
        
        div {
            border-radius: 16px;
            padding: 12px 16px 12px 16px;
            background-color: #FAF7FE;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;

            @include screen-xs {
                padding: 12px;
            }
            
            * {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #592D9B;
                text-align: center;
            }
        }
    }
}