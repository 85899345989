@import 'global/styles/variables';
@import 'global/styles/mixins';

.FeaturedSection {
    margin-top: 81px;
    border-radius: 24px;
    // overflow: hidden;
    height: 580px;
    position: relative;
    background-image: url('~global/assets/images/card-bg.png');
    background-repeat: no-repeat;
    background-color: #edeef0;
    background-position: right;
    background-size: cover;

    @include screen-sm {
        background-position: -50px;
    }

    @include screen-xs {
        background-position: -160px 90px;
    }

    &__content {
        position: absolute;
        width: 500px;
        height: 100%;
        top: 0;
        left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 43px;

        @include screen-sm {
            width: 100%;
        }

        @include screen-xs {
            left: 0px;
            padding: 0px 20px 0px 20px;
        }
    
        .title {
            font-size: 48px;
            font-weight: 600;
            color: $color-black;

            @include screen-xs {
                font-size: 38px;
            }
        }
    
        .description {
            font-size: 16px;
            font-weight: 400;
            width: 361px;
            line-height: 19.36px;
            color: $color-link;

            @include screen-xs {
                width: 100%;
            }
        }
    }
}
