//-------------------------------------//
// MEDIA QUERIES
$media-vlg: 1440px;
$media-mlg: 1396px;
$media-lg: 1200px;
$media-md: 992px;
$media-chat: 960px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette
// TODO_REPLACE(projects own color palette)
$color-black: #171717;
$color-primary: #3669FF;
$color-white: #fff;
$color-link: #5D5D5D;
$color-text-grey: #6E6E6E;
$color-navy-blue: #162A66;
$color-danger: #f24747;

//-------------------------------------//
// FONTS
// TODO_REPLACE(projects font families)
$font-family-Inter: 'Inter';

//-------------------------------------//
// SPACING

//-------------------------------------//
// Width
$width-leftnav: 181px;

//-------------------------------------//
// Z-INDEXING

// ...

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)
