@import 'global/styles/variables';
@import 'global/styles/mixins';

.GetYourStormCardToday {
    height: 291px;
    background-color: $color-navy-blue;
    border-radius: 24px;
    position: relative;
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: hidden;

    &__label1{
        font-size: 24px !important;
        font-weight: 500 !important;
        line-height: 29px !important;

        @include screen-sm {
            font-size: 15px;
            line-height: 25px;
        }
    }
    
    label {
        color: $color-white;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;

        @include screen-sm {
            font-size: 25px;
            line-height: 30px;
        }
    }

    

    &__circularPattern {
        position: absolute;
        width: 450px;
        height: 291px;
        right: 0px;
        top: 0px;
    }

    &__applyBtn {
        background-color: $color-white;
        width: 247px !important;

        * {
            color: $color-primary;
        }
    }
}