@import 'global/styles/variables';
@import 'global/styles/mixins';

.LandingPage {

    &__contentCenter {
        margin-top: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;


        &__sustainable {
            font-size: 52px;
            font-weight: 600;
            line-height: 68px;
            text-align: center;
            width: 780px;
            margin-bottom: 24px;

            @include screen-lg {
                font-size: 30px;
                line-height: 38px;
                width: 480px;
            }

            @include screen-sm {
                font-size: 20px;
                width: 100%;
            }
        }

        &__card {
            position: relative;
            margin-top: 99px;

            &__bg1, &__bg2 {
                top: -500px;
                position: absolute;
            }

            &__bg1 {
                left: 0px;
                z-index: -10;
                // background-color: red;
            }

            &__bg2 {
                right: 0px;
                z-index: -10;
                // background-color: green;
            }

            @include screen-sm {
                margin-top: 40px;
                svg {
                    width: 100%;
                }
            }

            @include screen-xs {
                margin-top: -16px;
            }
        }

        &__chooseStorm {
            margin-top: 96px;
            font-size: 40px;
            font-weight: 600;
            line-height: 48px;
            text-align: center;

            @include screen-sm {
                margin-top: 46px;
                font-size: 30px;
            }

            @include screen-xs {
                margin-top: -46px;
            }
        }

    }

    &__whyChooseStorm {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-top: 52px;
    }
}