.TextInput {
    margin-bottom: 1rem;

    &__container{
        display: flex;
        border-radius: 0.25rem;
        background-color: #E7E8EC;
        overflow: hidden;

        &__leftElement {
            display: flex;
            flex-direction: column;
            height: '100%';
            justify-content: center;
            align-items: center;
            padding: 0px 10px 0px 10px;
            
            * {
                font-size: 1rem;
                color: #495057;
            }
        }
    }

    &__label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &__input {
        display: block;
        flex: 1;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #E7E8EC;
        background-clip: padding-box;
        border: 1px solid #E7E8EC;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    &__warning-message {
        margin-top: 5px;
    }
}