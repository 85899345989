@import-normalize;
@import 'variables';
@import '_mixins';
@import '_normalize';
@import '_global';

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter.ttf') format('opentype');
}

html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
input,
button,
select,
textarea {
    font-family: $font-family-Inter;
    color: $color-black;
    line-height: 1;
    font-weight: 400;
}

* {
    /* Firefox */
    scrollbar-width: thin;
    // scrollbar-color: #444444 $color-primary;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        background: $color-primary;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #444444;
        border-radius: 14px;
        border: 3px solid $color-primary;
    }
}

.warning-message {
    font-size: 11px !important;
    color: $color-danger;
    font-style: italic;
}
