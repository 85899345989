@import 'global/styles/variables';
@import 'global/styles/mixins';

.AboutUsFeeToSignup {
    margin-top: 96px;
    border-radius: 16px;
    background-color: #FAF7FE;
    min-height: 593px;
    display: flex;
    padding: 24px;
    align-items: center;

    & > img {
        object-fit: cover;

        @include screen-sm {
            display: none;
        }
    }

    & > div {
        flex: 1;
        display: flex;
        flex-direction: column;

        & > span {
            font-size: 32px;
            font-weight: 600;
            line-height: 44px;
            letter-spacing: 0.2px;
        }

        & > div {
            display: flex;

            @include screen-sm {
                flex-direction: column;
            }


            &.AboutUsFeeToSignup__steps {
                margin-top: 48px;
                gap: 24px;
                justify-content: space-between;
                min-height: 0px;

                & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    max-width: 258px ;

                    @include screen-sm {
                        max-width: unset ;
                    }

                    
                    .AboutUsFeeToSignup__steps__step {
                        min-width: 48px;
                        min-height: 48px;
                        max-width: 48px;
                        max-height: 48px;
                        background-color: #6C36BD;
                        justify-content: center;
                        border-radius: 12px;
                        display: flex;

                        * {
                            align-self: center;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 28px;
                            color: $color-white
                        }
                    }

                    .AboutUsFeeToSignup__steps__title {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        height: 72px;
                        margin-top: 16px;
                    }

                    .AboutUsFeeToSignup__steps__description {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $color-text-grey;
                    }
                }
            }

            &.AboutUsFeeToSignup__actions {
                margin-top: 24px;
                gap: 24px;
            }
        }
    }

    &__contactBtn {
        background-color: transparent !important;
        border: 1px solid $color-primary;

        span {
            color: $color-primary;
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
        }
    }

    &__applyBtn {
        span {
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
        }
    }

    
}