@import 'global/styles/variables';
@import 'global/styles/mixins';

.SubmitSuccess {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    // background-color: red;

    &__centerContainer {
        z-index: 100;
        width: 537px;
        background-color: $color-white;
        border-radius: 24px;
        padding: 24px;
        display: flex;
        flex-direction: column;

        & > span {
            margin-top: 24px;
            margin-bottom: 64px;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
        }
    }

    &__bg {

        &__bg1 {
            position: absolute;
            top: 0px;
            left: 300px;
        }
        &__bg2 {
            position: absolute;
            top: 0px;
            right: 300px;
        }
    }
}