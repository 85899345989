@import 'global/styles/variables';
@import 'global/styles/mixins';

.CreditRequired {
    margin-bottom: 1rem;

    &__label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    & > div {
        display: flex;
        gap: 24px;

        @include screen-sm {
            flex-direction: column;
        }

        .CreditRequired__item {
            display: flex;
            background-color: #E7E8EC;
            padding: 12px 16px 12px 16px;
            flex: 1;
            border-radius: 12px;
            justify-content: space-between;

            & > div {
                display: flex;
                flex-direction: column;

                & > span {
                    &:first-child {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        color: $color-link;
                    }
    
                    &:last-child {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}