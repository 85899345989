@import 'global/styles/variables';
@import 'global/styles/mixins';

.PublicPageLayout-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1224px;

    @include screen-mlg {
        padding: 0 1rem;
    }

    @include screen-lg {
        max-width: 1024px;
    }

    @include screen-md {
        max-width: 95%;
    }

    &__header {
        display: flex;
        flex-direction: row;
        padding-top: 33px;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &__menu {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 24px;
            justify-content: center;

            @include screen-lg {
                display: none;
            }

            &__item{
                font-size: 20px;
                font-weight: 500;
                color: $color-link;

                &__active {
                    font-weight: 700;
                }
            }
        }

        &__btn {
            @include screen-lg {
                display: none;
            }
        }
    }
}