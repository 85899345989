.PrivacyPolicyPage {
    &__p {
        margin-top:0pt; margin-bottom:8pt; line-height:145%;

        & > strong {
            font-weight: bolder;

            * {
                font-weight: bolder;
            }
        }
    }

    &__table {
        width: 100%;
        border:0.75pt solid #000000; border-collapse:collapse;

        &__col_header1 {
            border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top;
    
            &__text {
                margin-top:0pt; margin-bottom:0pt; line-height:120%; font-size:11pt;

                & > strong {
                    font-weight: bolder;

                        * {
                            font-weight: bolder;
                        }
                }
            }
        }

        &__col_header2 {
            border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top;
    
            &__text {
                margin-top:0pt; margin-bottom:0pt; line-height:120%; font-size:11pt;

                & > strong {
                    font-weight: bolder;

                    * {
                        font-weight: bolder;
                    }
                }
            }
        }

        &__col_td1 {
            border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top;
    
            &__text {
                margin-top:0pt; margin-bottom:0pt; line-height:120%; font-size:11pt;
            }
        }

        &__col_td2 {
            border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top;
    
            &__text {
                margin-top:0pt; margin-bottom:0pt; line-height:120%; font-size:11pt;
            }
        }

    }
}