@import 'global/styles/variables';
@import 'global/styles/mixins';

.MobileNav {
    display: none;

    @include screen-lg {
        display: inline-block;
    }

    &__button {
        width: 20px;
        height: 15px;
        cursor: pointer;
        position: fixed;
        display: inline-block;
        top: 51px;
        right: 91px;
        // float: right;
        z-index: 5;

        @include screen-sm {
            right: 41px !important;
        }

        &__background {
            pointer-events: none;
            width: 45px;
            min-width: 0;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: $color-primary;
            transition:
                width 0.6s cubic-bezier(0.2, 1, 0.2, 1) 0s,
                min-width 0.6s cubic-bezier(0.2, 1, 0.2, 1) 0s;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

            &__ratio {
                width: 100%;
                padding-bottom: 100%;
            }
        }

        &__bars-container {
            height: 20px;
            z-index: 100;

            &__bar {
                width: 100%;
                height: 2px;
                background: #fff;
                position: absolute;
                z-index: 2;
                transition: transform 0.5s, top 0.75s, left 0.75s, width 0.75s;

                &:nth-of-type(1) {
                    left: 0;
                    top: 0;
                }

                &:nth-of-type(2),
                &:nth-of-type(3) {
                    left: 0;
                    top: 50%;
                }

                &:nth-of-type(4) {
                    left: 0;
                    top: 100%;
                }
            }
        }
    }

    &__nav-contents {
        &__items-container {
            width: 100%;
            text-align: center;
            position: fixed;
            top: calc(50vh - 55px);
            left: -20%;
            transform: translateY(-50%);
            z-index: 5;
            visibility: hidden;
            opacity: 0;
            transition:
                left 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55),
                opacity 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &__item {
                font-size: 40px;
                text-align: center;
                font-weight: 700;
                color: #fff;
                list-style: none;

                @include screen-sm {
                    font-size: 32px;
                }

                @include screen-xs {
                    font-size: 28px;
                }

                &:not(:last-child) {
                    margin-bottom: 4.5vh;
                }
            }
        }
    }

    &__button-row {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: calc(100vh - 110px);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &__item {
            display: flex;
            align-items: center;
            font-size: 40px;
            font-weight: 700;
            color: #fff;

            &:not(:last-child) {
                margin-right: 50px;
            }


            @include screen-sm {
                font-size: 30px;

                svg {
                    width: 28px;
                }
            }

            @include screen-xs {
                font-size: 22px;
            }
        }
    }

    &--show {
        .MobileNav__button__background {
            transform: scale(80);
            transition: transform 1.3s cubic-bezier(0.2, 1, 0.2, 1) 0s;
        }

        .MobileNav__button__bars-container__bar {
            &:nth-of-type(1),
            &:nth-of-type(4) {
                width: 0;
                top: 50%;
                left: 50%;
            }

            &:nth-of-type(2) {
                transform: rotate(45deg);
            }

            &:nth-of-type(3) {
                transform: rotate(-45deg);
            }
        }

        .MobileNav__nav-contents__items-container {
            visibility: visible;
            opacity: 1;
            left: 0;
        }

        .MobileNav__button-row {
            opacity: 1;
            visibility: visible;
        }
    }
}
