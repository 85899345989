// stylelint-disable at-rule-no-unknown
// ------------------------------------- //
// mixins for media queries

@mixin screen-vlg {
    @media (max-width: $media-vlg) {
        @content;
    }
}

@mixin screen-mlg {
    @media (max-width: $media-mlg) {
        @content;
    }
}

@mixin screen-lg {
    @media (max-width: $media-lg) {
        @content;
    }
}

@mixin screen-md {
    @media (max-width: $media-md) {
        @content;
    }
}

@mixin screen-chat {
    @media (max-width: $media-chat) {
        @content;
    }
}

@mixin screen-sm {
    @media (max-width: $media-sm) {
        @content;
    }
}

@mixin screen-xs {
    @media (max-width: $media-xs) {
        @content;
    }
}

// ------------------------------------- //
// mixins for components
