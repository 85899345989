
@import 'global/styles/variables';
@import 'global/styles/mixins';

.LandingPageFooter {
    display: flex;
    gap: 70px;
    margin-top: 94px;
    padding-bottom: 90px;

    @include screen-xs {
        flex-direction: column;
    }

    * {
        font-weight: 400;
        font-size: 20px;
        color: $color-text-grey;
    }

    &__links {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 21px;
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        gap: 21px;
    }
}