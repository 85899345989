@import 'global/styles/variables';
@import 'global/styles/mixins';

.PlanToUseStorm {
    margin-bottom: 1rem;

    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &__items {
        gap: 8px ;
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        div {
            display: flex;
            align-items: center;
            gap: 8px;
    
            input {
                border: 1px solid #D0D5DD;
                height: 20px;
                width: 20px;
                border-radius: 6px;
                outline: none;
                vertical-align: middle;
                appearance: none;
                -webkit-appearance: none;
    
                &:checked {
                    background-color: $color-primary;
                }
            }
    
            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #5D5D5D;
            }
        }
    }
}