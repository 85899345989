@import 'global/styles/variables';
@import 'global/styles/mixins';

.HowMuchStormCost {
    margin-top: 96px;
    display: flex;
    gap: 32px;

    @include screen-sm {
        flex-direction: column;
    }

    div {
        flex: 1;
        position: relative;

        &.HowMuchStormCost__left {
            display: flex;
            align-items: center;


            &__content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                height: 204px;

                & > h1, & > span {
                    text-align: left;
                    width: 382px;

                    @include screen-xs {
                        width: unset;
                    }
                }

                & > span {
                    line-height: 24px;
                }

                &__actions {
                    display: flex;
                    gap: 24px;

                    &__applyBtn {
                        z-index: 0;
                    }

                    &__contactBtn {
                        background: transparent !important;
                        border: 1px solid $color-primary;

                        * {
                            color: $color-primary !important;
                        }
                    }
                }
            }
        }

        &.HowMuchStormCost__right {
            background-image: url('~global/assets/images/phone-card.png');
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            border-radius: 16px;
            height: 306px;
        }
    }
}