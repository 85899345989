@import 'variables';

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-jsb {
    justify-content: space-between;
}

.flex-jsa {
    justify-content: space-around;
}

.flex-js {
    justify-content: flex-start;
}

.flex-je {
    justify-content: flex-end;
}

.flex-jc {
    justify-content: center;
}

.flex-as {
    align-items: flex-start;
}

.flex-ae {
    align-items: flex-end;
}

.flex-ac {
    align-items: center;
}

.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.flex-5 {
    flex: 5;
}
.flex-6 {
    flex: 6;
}

.wrapper-header {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}
.simple-editor {
    height: auto !important;
}
.full-height-wrapper-container {
    display: flex;
    flex-direction: column;
}
