.InputGroup {
    margin-bottom: 1rem;
    

    &__label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &__inputContent {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        background-color: #E7E8EC;

        border: 1px solid #E7E8EC;
        border-radius: 0.25rem;
    }

    &__input {
        flex: 1;
        display: block;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: inherit;
        background-clip: padding-box;
        border: 1px solid #E7E8EC;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &__group-append {
        margin-left: -1px;
        border-left: 1px solid #9DA2B2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;

        &__select {
            height: 100%;
            width: 100%;
            background-color: inherit;
            border: 0px;

            outline: none;
        }
    }
}