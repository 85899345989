@import 'global/styles/variables';
@import 'global/styles/mixins';

.StormBenifits {
    display: flex;
    align-items: center;
    margin-top: 96px;
    gap: 24px;

    @include screen-sm {
        flex-direction: column;
        align-items: flex-start;
    }

    label {
        max-width: 288px;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.2px;
        
        @include screen-sm {
            max-width: unset;
        }
    }

    &__contents {
        flex: 1;
        display: flex;
        justify-content: space-between;

        @include screen-sm {
            width: 100%;
        }
        

        &__items {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-top: 103px;
            gap: 45px;
            width: 151px;
            align-items: center;
            border-radius: 16px;
            padding-bottom: 16px;
            box-shadow: 0px 3px 9.600000381469727px 0px rgba(0, 0, 0, 0.08);

            

            &:first-child {
                box-shadow: unset;
                width: unset;

                @media (max-width: 400px) {
                    gap: 41px !important;
                }

                @include screen-sm {
                    gap: 40px;
                }
                
            }

            &:nth-child(2), &:nth-child(3) {

                @include screen-sm {
                    width: 165px;

                    svg {
                        width: 80% !important;
                    }
                }

                @media (max-width: 480px) {
                    gap: 51px;
                }

                @media (max-width: 380px) {
                    gap: 61px;
                }
            }

            &:last-child {

                @include screen-sm {
                    width: 165px;

                    *{
                        font-size: 20px;
                        line-height: 25px;
                    }
                }

                @media (max-width: 480px) {
                    gap: 51px;
                }

                @media (max-width: 380px) {
                    gap: 61px;
                }
            }

            &__icon {
                position: absolute;
                top: 7px;

                @include screen-md {
                    flex-direction: column;
                    top: 20px;

                    svg {
                        width: 100px;
                    }

                }

                @include screen-sm {
                    top: 30px;
                    text-align: center;
                    svg {
                        width: 40px;
                    }

                }
            }

            &__listCTitle {
                position: absolute;
                top: 16px;
                width: 100%;
                display: flex;
                gap: 12px;
                align-items: center;
                justify-content: center;
                

                * {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }

                @include screen-md {
                    flex-direction: column;
                    gap: 5px;

                    span {
                        text-align: center;
                    }
                }

                @include screen-lg {
                    font-size: 18px;
                }
            }


            &__title {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $color-text-grey;
                align-self: flex-start;
                display: flex;
                align-items: center;
                gap: 12px;
            }
        }
        

    }
}