@import 'global/styles/variables';
@import 'global/styles/mixins';

.AboutUs {

    &__whyChooseStorm {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-top: 52px;
    }
}