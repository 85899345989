@import 'global/styles/variables';
@import 'global/styles/mixins';

.ChooseStoremItem {
    display: flex;
    gap: 24px;
    align-items: center;
    @include screen-sm {
        flex-direction: column;
        margin-bottom: 20px;
    }

    &__img {
        flex: 1;
        height: 306px;
        border-radius: 16px;
        object-fit: cover;

        @include screen-lg {
            flex: unset;
            width: 40%;
        }

        @include screen-sm {
            display: none;
        }
    }

    &__textContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include screen-sm {
                width: 100%;

                svg {
                    display: none;
                }
            }
            

            

            &__title {
                font-size: 32px;
                font-weight: 600;
                line-height: 44px;
                letter-spacing: 0.2px;
                
                @include screen-sm {
                    font-size: 28px;
                }
            }

            &__description {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $color-text-grey;
            }
        }
    }

}