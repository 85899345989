@import 'global/styles/variables';

.Button {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: $color-primary;
    padding: 12px 20px 12px 20px;
    z-index: 1000;

    &:hover {
        cursor: pointer;
    }

    &__text {
        color: $color-white;
        font-weight: 600;
        font-size: 16px;
    }
}