@import 'global/styles/variables';
@import 'global/styles/mixins';

.WhatsIncluded {
    background-color: #F7F9FF;
    border-radius: 16px;
    padding: 22px 16px 22px 16px;
    display: flex;
    flex-direction: column;

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
    }

    & > div {
        display: flex;
        gap: 24px;
        margin-top: 6px;

        & > div {
            width: 173px;
            padding: 12px, 16px, 12px, 16px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #592D9B;
        }
    }
}