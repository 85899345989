@import 'global/styles/variables';
@import 'global/styles/mixins';

.StormBusinessCard {
    margin-top: 65px;

    h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.2px;
        text-align: center;
    }

    &__options {
        display: flex;
        margin-top: 48px;
        gap: 32px;

        @include screen-xs {
            height: unset;
        }


        &__storm {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            border-radius: 16px;
            background-color: #F7F9FF;
            padding: 32px;
            gap: 20px;
        }

        &__virtual {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            border-radius: 16px;
            background-color: #6C36BD;
            padding: 32px;
            gap: 20px;
            
            * {
                color: $color-white;
            }
        }

        &__type {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            
            @include screen-xs {
                font-size: 15px;
                line-height: 20px;
                text-align: center;
            }
        }

        &__price {
            font-weight: 600;
            font-size: 48px;
            line-height: 60px;
            letter-spacing: 0.2px;
            text-align: center;

            @include screen-xs {
                font-size: 38px;
                line-height: 50px;
            }
        }

        &__description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
    }
}