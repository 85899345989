@import 'global/styles/variables';
@import 'global/styles/mixins';

.Checkbox {
    position: relative;
    cursor: pointer;

    &__input {
        border: 1px solid #D0D5DD;
        height: 20px;
        width: 20px;
        border-radius: 6px;
        outline: none;
        vertical-align: middle;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;


        &:checked {
            background-color: $color-primary;
        }

        &:checked ~ .Checkbox__checkmark{
            display: block;
        }
    }

    &__checkmark {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}