@import 'global/styles/variables';
@import 'global/styles/mixins';

.SignUp {
    position: relative;

    padding-bottom: 30px;
    
    &__goback {
        background: transparent !important;
        border: 1px solid $color-primary;

        * {
            color: $color-primary !important;
        }

        @include screen-xs {
            width: 120px;

            * {
                font-size: 14px;
                text-align: center;
            }
        }
    }
    

    &__InputGroup {
        margin-bottom: unset !important;
        flex: 1;
        margin-right: 1px;
    }


    &__form {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;

        &__name {
            display: flex;
            gap: 24px;

            & > div {
                flex: 1
            }
        }

        &__contact {
            display: flex;
            gap: 8px;
            padding: 12px 16px 12px 16px;
            background-color: #F7F9FF;
            border-radius: 12px;
            margin-top: 24px;
            margin-bottom: 24px;
            align-items: center;

            span {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color: $color-link
            }
        }
    }
}